import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useLenis } from '@studio-freight/react-lenis'
import { theme } from 'app/theme'
import { getCookie } from 'app/utils/customFunctions'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo, ReactNode, useEffect } from 'react'
import { useCookies } from 'react-cookie'

interface Props {
  children: ReactNode
  location: any
  pageContext: any
}

export const Transition = memo(function Transition({
  children,
  location,
  pageContext,
}: Props) {
  const lenisInstance = useLenis()

  const [cookies, setCookie] = useCookies(['pageLoaded'])

  useEffect(() => {
    history.scrollRestoration = 'manual'
  })

  return (
    <AnimatePresence mode="wait">
      <Main key={location.pathname}>
        <SlideIn
          initial={{ scaleY: 0 }}
          animate={{ scaleY: 0 }}
          exit={{ scaleY: 1 }}
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
          onAnimationStart={() => {
            lenisInstance?.stop()
          }}
          onAnimationComplete={() => {
            lenisInstance?.start()
          }}
        />
        <SlideOut
          initial={{ scaleY: getCookie('pageLoaded') ? 1 : 0 }}
          animate={{ scaleY: 0 }}
          exit={{ scaleY: 0 }}
          transition={{
            duration: 1,
            ease: [0.22, 1, 0.36, 1],
          }}
          onAnimationStart={() => {
            lenisInstance?.stop()

            setCookie('pageLoaded', true, {
              expires: new Date(Date.now() + 1000 * 15),
            })
          }}
          onAnimationComplete={() => {
            lenisInstance?.start()
          }}
        />

        {children}
      </Main>
    </AnimatePresence>
  )
})

const Main = styled(motion.main)``

const Style = css`
  width: 100%;
  height: 100vh;
  background: ${theme.colors.variants.neutralLight1};
  position: fixed;
  top: 0;
  left: 0;
  transform-origin: bottom;
  z-index: 10000;
`

const SlideIn = styled(motion.aside)`
  ${Style}

  transform-origin: bottom;
`

const SlideOut = styled(motion.aside)`
  ${Style}

  transform-origin: top;
`

import { MotionValue, useTransform } from 'framer-motion'

export const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export function getCookie(key: string) {
  var b =
    typeof window !== 'undefined'
      ? window.document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)')
      : null

  return b ? b.pop() : ''
}

export function dateFormatter(date: Date, languageCode: string) {
  if (!date) {
    return null
  }
  if (typeof window === 'undefined') {
    return null
  }

  return new window.Date(date).toLocaleDateString(languageCode || 'it-IT', {
    year: 'numeric',
  })
}

export function itemStringReplace(a: string) {
  return a.replace(/[^a-zA-Z]/g, '')
}

export function positionStyle(ref: any, details: any, idx: any) {
  if (!details) {
    return undefined
  }

  const containerWidth = ref.current?.size

  const position = details.slides[idx].distance
  const x = parseInt(containerWidth) * -position

  return {
    transform: `translate3d(${x + position * (containerWidth / 2)}px,0,0)`,
    WebkitTransform: `translate3d(${x + position * (containerWidth / 2)}px,0,0)`,
  }
}

export function useParallax(
  value: MotionValue<number>,
  distance: [number, number],
) {
  return useTransform(value, [0, 1], [distance[0], distance[1]])
}

export const easeOutQuint = (x: number) => {
  return 1 - Math.pow(1 - x, 5)
}
